<style type="text/css">
  @import "~@/assets/css/common.css";
</style>

<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    tipsScanList,
  } from "@/api/web/tips.js";
  /**
   * 推送信息浏览记录
   */
  export default {
    components: {
      Layout,
      PageHeader,

    },
    data() {
      return {
        title: "推送信息 ",
        title2:"",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        query:{
          keyword: "",
          pageNum: 1,
          pageSize: 20,
          ydzt:null,
          zdzt:null,
          tid:null,
        },
        checked:true,
        list:[],
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0,
        // 发布状态
        statusLsit: [{
          name: "已阅读",
          val: "1",
        },
          {
            name: "未阅读",
            val: "0",
          },
        ],
        zdztStatusLsit: [{
          name: "已指定",
          val: "1",
        },
          {
            name: "未指定",
            val: "0",
          },
        ],
        module: "XXTSMD",
        importTitle: "推送信息名单导入",
      };
    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询浏览记录列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        tipsScanList(this.query).then(res => {
          if (res.status) {
            this.list = res.data
            this.total = res.total
          }
        });
      },
      uploadFile(){
        var _this = this;
        _this.$importModal().show({
          title: this.importTitle,
          module: this.module,
          tid: this.query.tid,
          success: this.getList
        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
      goToListPage(){
        this.$router.push({
          path: '/admin/tips',
          name: 'tips'
        })
      }
    }
    ,
    mounted() {
      this.query.tid = this.$route.query["tid"] ? this.$route.query["tid"] : "";
      this.query.zdzt =  this.$route.query["zdzt"]
      this.query.ydzt =  this.$route.query["ydzt"]
      if (this.query.tid == undefined || this.query.tid == '') {
        this.$message({
          type: 'error',
          message: "参数有误"
        }).then(this.goToListPage())
      }else{
        this.title2 = "";
      }
      this.getList();
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :title2="title2" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">

            <div class="pb-3 check-table-top">
              <form class="flexList" style="flex: 1;">
                <el-select class=" mr-2 " style="width:8%;" clearable placeholder="请选择" v-model="query.ydzt"
                           size="small">
                  <el-option v-for="(item, i) in statusLsit" :label="item.name" :value="item.val" :key="i">
                  </el-option>
                </el-select>
                <el-select class=" mr-2 " style="width:8%;" clearable placeholder="请选择" v-model="query.zdzt"
                           size="small">
                  <el-option v-for="(item, i) in zdztStatusLsit" :label="item.name" :value="item.val" :key="i">
                  </el-option>
                </el-select>
                <input placeholder="输入证件号码或姓名" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15" maxlength="50"/>
                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick()"><i class="iconfont mr-2 icon-mb-search"></i>查询</b-button>

                <el-button size="small" type="primary" plain class="flexList mr-2 h30 " @click="uploadFile"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>导入指定名单</el-button>

                <el-button size="small" type="primary" plain class="flexList mr-2 h30 " @click="goToListPage">返回</el-button>
              </form>
              <div class="d-flex">
                <div class="border-blue export-tab"
                     @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:pageData})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                     @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:pageData})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:4%"> 序号</th>
                    <th style="width:20%">证件号</th>
                    <th style="width:7%">姓名</th>
                    <th style="width:7%">指定状态</th>
                    <th style="width:7%">阅读状态</th>
                    <th style="width:7%">阅读时间</th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(obj, index) in list" :key="index">
                  <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.zjhm}}</td>
                    <td>{{obj.xm}}</td>
                    <td>
                      <div class="line1 text-success" v-if="obj.zdzt"  title="已阅读">指定</div>
                    </td>
                    <td>
                      <div class="line1 text-success" v-if="obj.ydzt"  title="已阅读">已阅读</div>
                      <div class="line1 text-danger" v-if="!obj.ydzt"  title="未阅读">未阅读</div>
                    </td>
                    <td>
                      <div v-if="obj.ydzt" class="line1 text-success">{{obj.modifyTime.substring(0,16)}}</div>
                    </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="currentPage" :page-size="perPage"
                             layout="total, sizes,jumper, prev, pager, next" :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
