import { getRequest, postRequest, patchRequest, deleteRequest,putRequest } from "@/libs/axios";

// ------------------------公共信息 管理-----------------------
// 推送信息列表
export function tipsList(params) {
    return getRequest("/yethan/web/tips/listPage", params);
}
// 推送信息详情
export function tipsDetail(tid) {
    return getRequest("/yethan/web/tips/"+tid);
}
// 推送信息新增
export function saveTips(params) {
    return postRequest("/yethan/web/tips", params);
}
// 推送信息编辑
export function editTips(params) {
    return putRequest("/yethan/web/tips", params);
}
// 推送信息修改发布状态
export function editStatus(params) {
    return patchRequest("/yethan/web/tips", params);
}
// 推送信息删除
export function deleteTips(tid) {
    return deleteRequest("/yethan/web/tips/"+tid);
}


//指定阅读考生记录
export function tipsScanList(params) {
    return getRequest("/yethan/web/tipsScan/listPage", params);
}


